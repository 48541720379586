import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService';


export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            lastImageHTML: "",
            lastImageDate: "",
        };
    }

    componentDidMount() {
        this.populateLastImage();
    }

    async populateLastImage() {
        const token = await authService.getAccessToken();
        const response = await fetch('lastimage', {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        const img = <div><table class="paddingBetweenCols"><tr><td><p>You are viewing the Remote Eye</p><p>Latest image taken at {data.description}</p></td><td><img src={data.original} alt="" height="400" /></td></tr></table></div>;
        this.setState({ lastImageHTML: img, loading: false });
    }

    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : this.state.lastImageHTML;

    return (
        <div>
            <h3 class="full-width-green">
                <div><img src={require("../images/doc_logo_basic_green.png")} alt="Department of Conservation logo" height="100" /></div>
            </h3>
            <p></p>
            {contents}
            <p></p>
            <table class="paddingBetweenCols"><tr><td><img src={require("../images/remote_eye_logo.png")} alt="Remote Eye logo" height="150" /></td><td><p>Contact for outages:  Jason or Richard on 0274 940250, or email - support@remoteeye.nz</p></td></tr></table>
      </div>
    );
  }
}
