import React, { Component } from 'react';
import ModalImage from "react-modal-image";
import DatePicker, { registerLocale } from "react-datepicker";
import MaterialTable from "material-table";
import "react-datepicker/dist/react-datepicker.css";
import nz from 'date-fns/locale/en-NZ';
import authService from './api-authorization/AuthorizeService'
registerLocale('en-NZ', nz)

export class ImageList extends Component {
    displayName = ImageList.name

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            startDate: new Date(),
            images: []
        };
        this.setState({ startDate: new Date(Date.now()) });
    }

    componentDidMount() {
        this.populateImageData();
    }

    handleDate = (date) => {
        this.setState({ startDate: date });
        this.setState({ images: [] });
        this.populateImageData();
    }



    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : ImageList.renderImagesTable(this.state.images);
        return (
            <div>
                <div>
                    <h1>Image List</h1>
                    <p>Select a date to view images taken on that day.</p>
                    <DatePicker locale={nz}
                        selected={this.state.startDate}
                        onChange={this.handleDate}
                        dateFormat="dd/MM/yyyy"
                        inline />
                </div>
                {contents}
            </div>
        );
    }

    async populateImageData() {
        const token = await authService.getAccessToken();
        const response = await fetch('imagelog?displayDate=' + this.state.startDate.toISOString(), {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        this.setState({ images: data, loading: false });
    }


    static renderImagesTable(images) {
        return (
            <div>
                <link
                    rel="stylesheet"
                    href="https://fonts.googleapis.com/icon?family=Material+Icons"
                />
                <MaterialTable title="Image Log"
                    columns={[
                        {
                            title: "Row Key",
                            field: "rowKey",
                        },
                        {
                            title: "Image Name",
                            field: "fileName",
                        },
                        {
                            title: "Camera ID",
                            field: "cameraID",
                        },
                        {
                            title: "Timestamp",
                            field: "timestamp",
                        },
                        {
                            title: "View",
                            field: "url",
                            render: (rowData) =>
                            (<div>
                                <ModalImage
                                    small={rowData.url}
                                    large={rowData.url}
                                    alt="View Image"
                                />
                            </div>)
                        }
                    ]}
                    data={images}
                    options={{ search: true, paging: true, filtering: true, exportButton: true, grouping: true }}
                />
            </div>
        );
    }


}
