import React, { Component } from 'react';
import AliceCarousel from 'react-alice-carousel';
import "react-alice-carousel/lib/alice-carousel.css";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import nz from 'date-fns/locale/en-NZ';
import authService from './api-authorization/AuthorizeService'
registerLocale('en-NZ', nz)

export class Gallery extends Component {
    displayName = Gallery.name

    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            startDate: new Date(),
            galleryItems: []
        };

        this.setState({ startDate: new Date(Date.now()) });
    }

    componentDidMount() {
        this.populateImageData();
    }

    handleDate = (date) => {
        this.setState({ startDate: date });
        this.setState({ galleryItems: [] });
        this.populateImageData();
    }

    responsive = {
        0: { items: 1 },
        1024: { items: 2 },
    };


    render() {
        let contents = this.state.loading
            ? <p><em>Loading...</em></p>
            : Gallery.renderGallery(this.state.galleryItems);

        return (
            <div>
                <div>
                    <h1>Gallery</h1>
                    <p>Select a date to view images taken on that day.</p>
                    <DatePicker locale={nz}
                        selected={this.state.startDate}
                        onChange={this.handleDate}
                        dateFormat="dd/MM/yyyy"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                        timeCaption="time"
                        inline />
                </div>
                {contents}
            </div>
        );
    }

    async populateImageData() {
        const token = await authService.getAccessToken();
        const response = await fetch('imagegallery?displayDate=' + this.state.startDate.toISOString(), {
            headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
        });
        const data = await response.json();
        const img = data.map(m =>
            <img src={m.original} alt="" className="sliderimg" />
        )
        this.setState({ galleryItems: img, loading: false });
    }


    static renderGallery(images) {

        return (
            <AliceCarousel
                items={images}
                autoWidth
                autoPlayInterval={2000}
                autoPlayDirection="rtl"
                autoPlay={false}
                fadeOutAnimation={true}
                mouseTrackingEnabled={true}
                disableAutoPlayOnAction={true}
            />

        );
    }


}
