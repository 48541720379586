import React, { Component } from 'react';
import authService from './api-authorization/AuthorizeService'

export class FetchImages extends Component {
  static displayName = FetchImages.name;

  constructor(props) {
    super(props);
      this.state = { forecasts: [], startDate: new Date(), loading: true };
      this.setState({ startDate: new Date(Date.now()) });
  }

  componentDidMount() {
    this.populateImageData();
  }

  static renderForecastsTable(forecasts) {
    return (
      <table className='table table-striped' aria-labelledby="tabelLabel">
        <thead>
          <tr>
            <th>Date</th>
            <th>Temp. (C)</th>
            <th>Temp. (F)</th>
            <th>Summary</th>
          </tr>
        </thead>
        <tbody>
          {forecasts.map(forecast =>
              <tr key={forecast.rowKey}>
              <td>{forecast.timestamp}</td>
              <td>{forecast.fileName}</td>
              <td>{forecast.url}</td>
              <td>{forecast.cameraID}</td>
            </tr>
          )}
        </tbody>
      </table>
    );
  }

  render() {
    let contents = this.state.loading
      ? <p><em>Loading...</em></p>
      : FetchImages.renderForecastsTable(this.state.forecasts);

    return (
      <div>
        <h1 id="tabelLabel" >Weather forecast</h1>
        <p>This component demonstrates fetching data from the server.</p>
        {contents}
      </div>
    );
  }

  async populateImageData() {
    const token = await authService.getAccessToken();
      const response = await fetch('imagelog?displayDate=' +  this.state.startDate.toISOString(), {
      headers: !token ? {} : { 'Authorization': `Bearer ${token}` }
    });
    const data = await response.json();
    this.setState({ forecasts: data, loading: false });
  }
}
